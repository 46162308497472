// tslint:disable-next-line: no-implicit-dependencies
import { Middleware } from "@nuxt/types";

const middleware: Middleware = (context) => {
	const { app, redirect, route } = context;


	// get JWT from cookie.
	// this can be for a viewer, or simply a browsing session
	const token = app.$apolloHelpers.getToken();

	if (!token && !route.path.includes("/login")) {
		// redirect here
		const redirectTo = route.fullPath;
		const queryObject: {[key: string]: string} = {};

		if (redirectTo && redirectTo !== "/") {
			queryObject.redirect = redirectTo;
		}

		redirect("/login", queryObject);

		return;
	}
};

export default middleware;
