
import { Component, Vue } from "vue-property-decorator";
import ViewerQuery from "@/apollo/ViewerProfileQuery.graphql";
import checkAuthMiddlware from "../middleware/check-auth";
import { Viewer_viewer } from "../gql-typings/Viewer";

@Component({
	middleware: [checkAuthMiddlware],

	apollo: {
		viewer: {
			query: ViewerQuery,
		}
	}
})
export default class DefaultLayout extends Vue {
	viewer: Viewer_viewer | null = null;
}
