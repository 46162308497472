import Vue from "vue";

Vue.filter("formatDate", (value: string | Date, formatOptions: Intl.DateTimeFormatOptions = {
	timeZone: "UTC",
	year: "numeric",
	month: "short",
	day: "numeric",
}): string => {
	const defaultFormatOptions: Intl.DateTimeFormatOptions = {};

	formatOptions = {...defaultFormatOptions, ...formatOptions};

	const date = value instanceof Date ? value : new Date(value);

	return new Intl.DateTimeFormat("en-CA", formatOptions).format(new Date(date));
});
