import { render, staticRenderFns } from "./phonenumber.vue?vue&type=template&id=69c21a54"
import script from "./phonenumber.vue?vue&type=script&lang=ts"
export * from "./phonenumber.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports