// tslint:disable-next-line: no-implicit-dependencies
import { Context } from "@nuxt/types";
import { ApolloError } from "apollo-client/errors/ApolloError";

function isUnauthorizedError(error: ApolloError) {
	const { graphQLErrors } = error;

	return (
		graphQLErrors &&
		graphQLErrors
		.some(e => (
			["FORBIDDEN", "UNAUTHENTICATED"]
			.includes(e.extensions && e.extensions.code)
		))
	);
}

export default (error: ApolloError, ctx: Context) => {
	if (isUnauthorizedError(error)) {
		const {route, redirect} = ctx;

		// Redirect to login page
		if (route.name !== "login") {
			redirect("/logout", {
				redirect: route.fullPath,
			});

			return;
		}
	} else {
		console.log(error.message);

		if (error.extraInfo) {
			console.log(error.extraInfo);
		}

		if (error.graphQLErrors) {
			console.log(error.graphQLErrors);
		}

		const networkErrors = error.networkError && (error.networkError as any).result && (error.networkError as any).result.errors;

		if (networkErrors) {
			console.log(networkErrors);
		}
	}
};
