import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _7c6304cb = () => interopDefault(import('../pages/agent-prospects/index.vue' /* webpackChunkName: "pages/agent-prospects/index" */))
const _da909090 = () => interopDefault(import('../pages/agents/index.vue' /* webpackChunkName: "pages/agents/index" */))
const _5cda6903 = () => interopDefault(import('../pages/events/index.vue' /* webpackChunkName: "pages/events/index" */))
const _522ebd55 = () => interopDefault(import('../pages/login/index.vue' /* webpackChunkName: "pages/login/index" */))
const _61ac8434 = () => interopDefault(import('../pages/logout/index.vue' /* webpackChunkName: "pages/logout/index" */))
const _0644bd63 = () => interopDefault(import('../pages/offices/index.vue' /* webpackChunkName: "pages/offices/index" */))
const _341ec5c6 = () => interopDefault(import('../pages/properties/index.vue' /* webpackChunkName: "pages/properties/index" */))
const _530ce134 = () => interopDefault(import('../pages/users/index.vue' /* webpackChunkName: "pages/users/index" */))
const _2c9f3bfa = () => interopDefault(import('../pages/agent-users/new.vue' /* webpackChunkName: "pages/agent-users/new" */))
const _c5cecd8a = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _4d46c236 = () => interopDefault(import('../pages/agent-prospects/_id/index.vue' /* webpackChunkName: "pages/agent-prospects/_id/index" */))
const _1a0392ba = () => interopDefault(import('../pages/agents/_id/index.vue' /* webpackChunkName: "pages/agents/_id/index" */))
const _4b91c182 = () => interopDefault(import('../pages/brokerages/_id/index.vue' /* webpackChunkName: "pages/brokerages/_id/index" */))
const _e6e7ecf8 = () => interopDefault(import('../pages/listings/_id/index.vue' /* webpackChunkName: "pages/listings/_id/index" */))
const _27ea6ece = () => interopDefault(import('../pages/offices/_id/index.vue' /* webpackChunkName: "pages/offices/_id/index" */))
const _ea683cf0 = () => interopDefault(import('../pages/properties/_id/index.vue' /* webpackChunkName: "pages/properties/_id/index" */))
const _6d2b7bc2 = () => interopDefault(import('../pages/users/_id/index.vue' /* webpackChunkName: "pages/users/_id/index" */))
const _d8f57cc2 = () => interopDefault(import('../pages/agent-users/_id/demote.vue' /* webpackChunkName: "pages/agent-users/_id/demote" */))
const _5b84161a = () => interopDefault(import('../pages/agent-users/_id/set-subscription.vue' /* webpackChunkName: "pages/agent-users/_id/set-subscription" */))
const _0214f01a = () => interopDefault(import('../pages/agents/_id/create-source-lookup.vue' /* webpackChunkName: "pages/agents/_id/create-source-lookup" */))
const _d4d262a4 = () => interopDefault(import('../pages/agents/_id/listings.vue' /* webpackChunkName: "pages/agents/_id/listings" */))
const _58950191 = () => interopDefault(import('../pages/brokerages/_id/agents/index.vue' /* webpackChunkName: "pages/brokerages/_id/agents/index" */))
const _d88da490 = () => interopDefault(import('../pages/brokerages/_id/clients.vue' /* webpackChunkName: "pages/brokerages/_id/clients" */))
const _660a59c2 = () => interopDefault(import('../pages/offices/_id/agents.vue' /* webpackChunkName: "pages/offices/_id/agents" */))
const _5a01a2be = () => interopDefault(import('../pages/offices/_id/edit.vue' /* webpackChunkName: "pages/offices/_id/edit" */))
const _3c29f0c4 = () => interopDefault(import('../pages/properties/_id/edit.vue' /* webpackChunkName: "pages/properties/_id/edit" */))
const _1c58fda9 = () => interopDefault(import('../pages/properties/_id/listings.vue' /* webpackChunkName: "pages/properties/_id/listings" */))
const _1b78bbab = () => interopDefault(import('../pages/users/_id/agents-connected-to.vue' /* webpackChunkName: "pages/users/_id/agents-connected-to" */))
const _3e642b2c = () => interopDefault(import('../pages/users/_id/assign-agent.vue' /* webpackChunkName: "pages/users/_id/assign-agent" */))
const _051809d6 = () => interopDefault(import('../pages/users/_id/clients.vue' /* webpackChunkName: "pages/users/_id/clients" */))
const _4110a379 = () => interopDefault(import('../pages/users/_id/connect-to-agent.vue' /* webpackChunkName: "pages/users/_id/connect-to-agent" */))
const _eefcbd66 = () => interopDefault(import('../pages/users/_id/edit.vue' /* webpackChunkName: "pages/users/_id/edit" */))
const _3b5b53ba = () => interopDefault(import('../pages/users/_id/listings-guessed-close-price-on.vue' /* webpackChunkName: "pages/users/_id/listings-guessed-close-price-on" */))
const _31da867a = () => interopDefault(import('../pages/users/_id/listings-reacted.vue' /* webpackChunkName: "pages/users/_id/listings-reacted" */))
const _54414c05 = () => interopDefault(import('../pages/users/_id/listings-subscribed.vue' /* webpackChunkName: "pages/users/_id/listings-subscribed" */))
const _15e8b878 = () => interopDefault(import('../pages/users/_id/user-connectees.vue' /* webpackChunkName: "pages/users/_id/user-connectees" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/agent-prospects",
    component: _7c6304cb,
    name: "agent-prospects"
  }, {
    path: "/agents",
    component: _da909090,
    name: "agents"
  }, {
    path: "/events",
    component: _5cda6903,
    name: "events"
  }, {
    path: "/login",
    component: _522ebd55,
    name: "login"
  }, {
    path: "/logout",
    component: _61ac8434,
    name: "logout"
  }, {
    path: "/offices",
    component: _0644bd63,
    name: "offices"
  }, {
    path: "/properties",
    component: _341ec5c6,
    name: "properties"
  }, {
    path: "/users",
    component: _530ce134,
    name: "users"
  }, {
    path: "/agent-users/new",
    component: _2c9f3bfa,
    name: "agent-users-new"
  }, {
    path: "/",
    component: _c5cecd8a,
    name: "index"
  }, {
    path: "/agent-prospects/:id",
    component: _4d46c236,
    name: "agent-prospects-id"
  }, {
    path: "/agents/:id",
    component: _1a0392ba,
    name: "agents-id"
  }, {
    path: "/brokerages/:id",
    component: _4b91c182,
    name: "brokerages-id"
  }, {
    path: "/listings/:id",
    component: _e6e7ecf8,
    name: "listings-id"
  }, {
    path: "/offices/:id",
    component: _27ea6ece,
    name: "offices-id"
  }, {
    path: "/properties/:id",
    component: _ea683cf0,
    name: "properties-id"
  }, {
    path: "/users/:id",
    component: _6d2b7bc2,
    name: "users-id"
  }, {
    path: "/agent-users/:id?/demote",
    component: _d8f57cc2,
    name: "agent-users-id-demote"
  }, {
    path: "/agent-users/:id?/set-subscription",
    component: _5b84161a,
    name: "agent-users-id-set-subscription"
  }, {
    path: "/agents/:id/create-source-lookup",
    component: _0214f01a,
    name: "agents-id-create-source-lookup"
  }, {
    path: "/agents/:id/listings",
    component: _d4d262a4,
    name: "agents-id-listings"
  }, {
    path: "/brokerages/:id?/agents",
    component: _58950191,
    name: "brokerages-id-agents"
  }, {
    path: "/brokerages/:id?/clients",
    component: _d88da490,
    name: "brokerages-id-clients"
  }, {
    path: "/offices/:id/agents",
    component: _660a59c2,
    name: "offices-id-agents"
  }, {
    path: "/offices/:id/edit",
    component: _5a01a2be,
    name: "offices-id-edit"
  }, {
    path: "/properties/:id/edit",
    component: _3c29f0c4,
    name: "properties-id-edit"
  }, {
    path: "/properties/:id/listings",
    component: _1c58fda9,
    name: "properties-id-listings"
  }, {
    path: "/users/:id/agents-connected-to",
    component: _1b78bbab,
    name: "users-id-agents-connected-to"
  }, {
    path: "/users/:id/assign-agent",
    component: _3e642b2c,
    name: "users-id-assign-agent"
  }, {
    path: "/users/:id/clients",
    component: _051809d6,
    name: "users-id-clients"
  }, {
    path: "/users/:id/connect-to-agent",
    component: _4110a379,
    name: "users-id-connect-to-agent"
  }, {
    path: "/users/:id/edit",
    component: _eefcbd66,
    name: "users-id-edit"
  }, {
    path: "/users/:id/listings-guessed-close-price-on",
    component: _3b5b53ba,
    name: "users-id-listings-guessed-close-price-on"
  }, {
    path: "/users/:id/listings-reacted",
    component: _31da867a,
    name: "users-id-listings-reacted"
  }, {
    path: "/users/:id/listings-subscribed",
    component: _54414c05,
    name: "users-id-listings-subscribed"
  }, {
    path: "/users/:id/user-connectees",
    component: _15e8b878,
    name: "users-id-user-connectees"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
