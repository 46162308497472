
import { Component, Vue, Watch } from "vue-property-decorator";
import PhoneNumber from "../../components/phonenumber.vue";
import { E164Number, parsePhoneNumberFromString } from "libphonenumber-js";
import SendPhoneAccessCodeMutation from "@/apollo/SendPhoneAccessCodeMutation.graphql";
import SignInWithPhoneAccessCodeMutation from "@/apollo/SignInWithPhoneAccessCodeMutation.graphql";
import { SendPhoneAccessCodeMutationVariables, SendPhoneAccessCodeMutation as SendPhoneAccessCodeMutationResult} from "../../gql-typings/SendPhoneAccessCodeMutation"
import { SignInWithPhoneAccessCodeMutation as SignInWithPhoneAccessCodeMutationResult, SignInWithPhoneAccessCodeMutationVariables } from "../../gql-typings/SignInWithPhoneAccessCodeMutation"
import { ApolloError } from "apollo-client";

@Component<LoginPage>({
	layout: "login",

	head() {
		return {
			title: `Login`,
		}
	},

	asyncData({query}) {
		return {
			redirect: query.redirect as string,
		};
	},

	components: {
		PhoneNumber,
	},
})
export default class LoginPage extends Vue {
	redirect: string | null = null;

	phoneNumber: string = "";
	code: string = "";

	submitting: boolean = false;
	sent: boolean = false;

	signinError: string = "";

	get phoneOutput(): E164Number | null {
		try {
			const number = parsePhoneNumberFromString(this.phoneNumber, "CA");

			if (!number || !number.isValid()) {
				throw new Error("Invalid number");
			}

			return number.number
		} catch (e) {}

		return null;
	}

	@Watch("code")
	onCodeChanged(code: string) {
		if (code.length === 6) {
			this.signInWithCode(code);
		}
	}

	async sendCode() {
		const {phoneOutput: phone} = this;

		if (!phone) {
			return;
		}

		this.signinError = "";
		this.submitting = true;

		try {
			await this.$apollo.mutate<SendPhoneAccessCodeMutationResult, SendPhoneAccessCodeMutationVariables>({
				mutation: SendPhoneAccessCodeMutation,
				variables: {
					phoneNumber: phone,
				},
				errorPolicy: "all",
			});
		} catch (e) {

			if (e instanceof ApolloError && e.graphQLErrors) {
				const [gqlError] = e.graphQLErrors;

				if (gqlError) {
					this.signinError = "There was an issue in sending you an SMS. Please check your phone number and try again.";
					return;
				}
			}

			this.signinError = "There was an issue in sending you an SMS. Please try again.";

			return;
		} finally {
			this.submitting = false;
		}

		this.sent = true;

		const codeInput = this.$refs.codeInput as HTMLInputElement | undefined;

		if (codeInput) {
			codeInput.focus();
		}
	}

	async signInWithCode(code: string) {
		const {phoneOutput} = this;

		if (!phoneOutput || !code) {
			return;
		}

		let data;
		this.submitting = true;

		try {
			const mutationResult = await this.$apollo.mutate<SignInWithPhoneAccessCodeMutationResult, SignInWithPhoneAccessCodeMutationVariables>({
				mutation: SignInWithPhoneAccessCodeMutation,
				variables: ({
					phoneNumber: phoneOutput,
					accessCode: code,
				}),
				errorPolicy: "all",// needs to be set to 'all' over 'ignore' else it breaks, since `data` is null
			});

			if (!mutationResult) {
				throw new Error("No result returned");
			}

			({data} = mutationResult);

			if (!data) {
				throw new Error("No result returned");
			}

			if (mutationResult.errors && mutationResult.errors.length) {
				this.signinError = "Unable to verify your code. Please check your code and try again.";
				return;
			}
		} catch (e) {

			this.signinError = "There was an issue in verifying your code. Please try again.";
			this.code = "";

			return;
		} finally {
			this.submitting = false;
		}

		const {signInWithPhoneAccessCode} = data;

		this.submitting = true;

		try {
			// set the token in cookies
			await this.$apolloHelpers.onLogin(signInWithPhoneAccessCode);

			/*let redirect = "";

			try {
				redirect = await this.$store.dispatch("getAndClearRedirect");
			} catch (e) {
				this.$sentry?.captureException(e);
			}*/

			// redirect to another page to trigger viewer retrieval
			this.$router.push(this.redirect || "/");
		} finally {
			this.submitting = false;
		}
	}
}
