import Vue from "vue";
import { formatDistanceToNowStrict } from "date-fns";
import locale from 'date-fns/locale/en-US'

const formatDistanceLocale: {[key: string]: string} = {
	lessThanXSeconds: '{{count}}s',
	xSeconds: '{{count}}s',
	halfAMinute: '30s',
	lessThanXMinutes: '{{count}}m',
	xMinutes: '{{count}}m',
	aboutXHours: '{{count}}h',
	xHours: '{{count}}h',
	xDays: '{{count}}d',
	aboutXWeeks: '{{count}}w',
	xWeeks: '{{count}}w',
	aboutXMonths: '{{count}}M',
	xMonths: '{{count}}M',
	aboutXYears: '{{count}}y',
	xYears: '{{count}}y',
	overXYears: '{{count}}y',
	almostXYears: '{{count}}y',
};

function formatDistance(token: string, count: string, options?: any) {
	options = options || {}

	const result = formatDistanceLocale[token].replace('{{count}}', count)

	if (options.addSuffix) {
		if (options.comparison > 0) {
			return 'in ' + result
		} else {
			return result
		}
	}

	return result
}

Vue.filter("formatDistanceToNowStrict", (value: string): string => {
	return formatDistanceToNowStrict(new Date(value), {
		addSuffix: true,
		locale: {
			...locale,
			formatDistance,
		}
	});
});
